<template>
  <div class="equity">
    <!-- 顶部图 -->
    <div class="header-banner">
      <div class="container mx-auto title-box">
        <h1 class="banner-one-title">一站式营销服务</h1>
        <h1 class="banner-two-title">定制营销策划+全品类营销资源+专业技术支撑+一站式售后服务</h1>
        <button class="btn-line">立即咨询</button>
      </div>
    </div>
    <!-- 权益产品介绍 -->
    <div class="equity-introduce">
      <div class="container mx-auto">
        <h2 class="two-leave-title">权益产品</h2>
        <div class="tab-bar-menu">
          <ul class="flex">
            <li
              :class="[activeLiIndex === index && 'li-checked']"
              v-for="(item, index) in goodsList"
              :key="index"
              @click="onClickType(index)"
            >
              {{ item.title }}
            </li>
          </ul>
        </div>
        <div class="goods-center">
          <div class="goods flex">
            <div
              class="goods-card flex flex-col align-center justify-center"
              v-for="(item, index) in goodsDataList"
              :key="index"
              @click="onClickGoods(item, index)"
            >
              <img :src="item.icon" />
              <p :class="['desc', goodsIndex === index && 'desc-delected']">{{ item.title }}</p>
            </div>
          </div>
        </div>
        <div class="goods-info">
          <div class="goods-box">
            <p class="three-leave-ttitle">服务介绍</p>
            <p class="desc">
              服务定位：协助银行、运营商、保险、地产等行业实现大数据营销及场景孵化
              <br />
              服务内容：营销抓手（提升APP、公众号的活跃度），提供关键营销资源，提升市场竞争力
              <br />
              服务行业：银行、运营商、保险、地产、汽车、物流等领域
            </p>
          </div>
          <div class="goods-box">
            <p class="three-leave-ttitle">营销介绍</p>
            <p class="desc">
              权益抓手：车主营销工具、娱乐影音工具、学生营销工具、白领营销工具、好吃狗营销工具等
              <br />
              资源整合：加油出行、话费充值、视频影音、餐饮美食、健康生活、福利礼品等
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 权益生态场景 -->
    <div class="equity-ecology">
      <div class="container mx-auto">
        <h2 class="two-leave-title">权益生态场景</h2>
        <p class="desc">
          璐付科技致力于以产品数字化的便捷性，与不同社群及行业伙伴协同合作，运用全面的数字化产品及大数据驱动的深度学习技术，为银行、保险、信托类金融客户在用户拉新、转化、留存和促活等方面提供多样化营销解决方案。
        </p>
        <div class="fore-card">
          <ForeCard :dataList="foreCardList" />
        </div>
      </div>
    </div>
    <!-- 权益解决方案 -->
    <div class="equity-solutions">
      <div class="container mx-auto">
        <h2 class="two-leave-title">底层营销服务生态圈</h2>
        <p class="desc">
          璐付科技凭借多年行业经验的积累及市场资源的不断扩展，搭建场景丰富的底层营销服务生态圈，为银行、保险、运营商、车企、信托基金等大型企业在C端用户的拉新促活、企业降本增效等方面提供一站式营销服务解决方案。
        </p>
        <div class="fore-card">
          <ColumnCard :dataList="columnList" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// components
import ForeCard from '@/components/common/ForeCard';
import ColumnCard from '@/components/common/ColumnCard';

export default {
  components: {
    ForeCard,
    ColumnCard
  },
  data() {
    return {
      dataList: [
        {
          title: '滴滴出行',
          key: 0,
          icon: require('@/assets/img/equity-icon/api/didi.png'),
          introduce:
            '滴滴出行是涵盖出租车 、专车  、滴滴快车   、顺风车   、代驾及 大巴、货运等多项业务在内的一站式出行平台，2015年09月09日由“滴滴打车”更名而来。2020年2月27日，为了满足用户复工出行用车需求，小桔车服旗下的小桔租车已陆续在全国几大城市推出了“全国万台车 免费用7天”的租车服务。用户可通过小桔租车App或滴滴App进行免费预订 。',
          info: '滴滴快车5元代金券、滴滴快车10元代金券、滴滴快车15元代金券、滴滴快车20元代金券、滴滴快车30元代金券、滴滴快车50元代金券、滴滴专车10元代金券、滴滴专车20元代金券、滴滴专车30元代金券、滴滴专车50元代金券'
        },
        {
          title: '青桔出行',
          key: 0,
          icon: require('@/assets/img/equity-icon/api/qinju.png'),
          introduce: '青桔出行-优惠券/代金券介绍',
          info: '青桔出行-优惠券/代金券'
        },
        {
          title: '同城出行',
          key: 0,
          icon: require('@/assets/img/equity-icon/api/tongcheng.png'),
          introduce: '同城出行-优惠券/代金券介绍',
          info: '同城出行-优惠券/代金券'
        },
        {
          title: 'E代驾',
          key: 0,
          icon: require('@/assets/img/equity-icon/api/edaijia.png'),
          introduce: 'E代驾-优惠券/代金券介绍',
          info: 'E代驾-优惠券/代金券'
        },
        {
          title: '龙腾机场',
          key: 0,
          icon: require('@/assets/img/equity-icon/api/longteng.png'),
          introduce: '龙腾机场VIP厅-优惠券/代金券介绍',
          info: '龙腾机场VIP厅-优惠券/代金券'
        },
        {
          title: '哈罗单车',
          key: 0,
          icon: require('@/assets/img/equity-icon/api/haluodanche.png'),
          introduce: '哈罗单车-优惠券/代金券介绍',
          info: '哈罗单车-优惠券/代金券'
        },
        {
          title: '天猫',
          key: 3,
          icon: require('@/assets/img/equity-icon/api/tianmao.png'),
          introduce: '天猫-优惠券/代金券介绍',
          info: '天猫-优惠券/代金券'
        },
        {
          title: '虎票票',
          key: 3,
          icon: require('@/assets/img/equity-icon/api/hpp.png'),
          introduce: '虎票票-优惠券/代金券介绍',
          info: '虎票票-优惠券/代金券'
        },
        {
          title: '锦江酒店',
          key: 3,
          icon: require('@/assets/img/equity-icon/api/jinjiang.png'),
          introduce: '锦江酒店-优惠券/代金券介绍',
          info: '锦江酒店-优惠券/代金券'
        },
        {
          title: '天猫超市',
          key: 3,
          icon: require('@/assets/img/equity-icon/api/tianmaocaoshi.png'),
          introduce: '天猫超市-优惠券/代金券介绍',
          info: '天猫超市-优惠券/代金券'
        },
        {
          title: '菜鸟寄件券',
          key: 3,
          icon: require('@/assets/img/equity-icon/api/cainiaojijianquan.png'),
          introduce: '菜鸟寄件券-优惠券/代金券介绍',
          info: '菜鸟寄件券-优惠券/代金券'
        },
        {
          title: '京东卡',
          key: 3,
          icon: require('@/assets/img/equity-icon/api/jingdongeka.png'),
          introduce: '京东卡-优惠券/代金券介绍',
          info: '京东卡-优惠券/代金券'
        },
        {
          title: '猫眼',
          key: 2,
          icon: require('@/assets/img/equity-icon/api/maoyan.png'),
          introduce: '猫眼-优惠券/代金券介绍',
          info: '猫眼-优惠券/代金券'
        },
        {
          title: '百果园',
          key: 1,
          icon: require('@/assets/img/equity-icon/api/baiguoyuan.png'),
          introduce: '百果园-优惠券/代金券介绍',
          info: '百果园-优惠券/代金券'
        },
        {
          title: '肯德基',
          key: 1,
          icon: require('@/assets/img/equity-icon/api/kendeji.png'),
          introduce: '肯德基-优惠券/代金券介绍',
          info: '肯德基-优惠券/代金券'
        },
        {
          title: '饿了么',
          key: 1,
          icon: require('@/assets/img/equity-icon/api/eleme.png'),
          introduce: '饿了么-优惠券/代金券介绍',
          info: '饿了么-优惠券/代金券'
        },
        {
          title: '奈雪的茶',
          key: 1,
          icon: require('@/assets/img/equity-icon/api/laixuedecha.png'),
          introduce: '奈雪的茶-优惠券/代金券介绍',
          info: '奈雪的茶-优惠券/代金券'
        },
        {
          title: '三只松鼠',
          key: 1,
          icon: require('@/assets/img/equity-icon/api/sanzhi.png'),
          introduce: '三只松鼠-优惠券/代金券介绍',
          info: '三只松鼠-优惠券/代金券'
        },
        {
          title: '周黑鸭',
          key: 1,
          icon: require('@/assets/img/equity-icon/api/zhy.png'),
          introduce: '周黑鸭-优惠券/代金券介绍',
          info: '周黑鸭-优惠券/代金券'
        },
        {
          title: '麦当劳',
          key: 1,
          icon: require('@/assets/img/equity-icon/api/maidanglao.png'),
          introduce: '麦当劳-优惠券/代金券介绍',
          info: '麦当劳-优惠券/代金券'
        },
        {
          title: '美团外卖',
          key: 1,
          icon: require('@/assets/img/equity-icon/api/meituanwaimai.png'),
          introduce: '美团外卖-优惠券/代金券介绍',
          info: '美团外卖-优惠券/代金券'
        },
        {
          title: '喜茶',
          key: 1,
          icon: require('@/assets/img/equity-icon/api/xicha.png'),
          introduce: '喜茶-优惠券/代金券介绍',
          info: '喜茶-优惠券/代金券'
        },
        {
          title: '星巴克',
          key: 1,
          icon: require('@/assets/img/equity-icon/api/xingbake.png'),
          introduce: '星巴克-优惠券/代金券介绍',
          info: '星巴克-优惠券/代金券'
        },
        {
          title: '瑞幸咖啡',
          key: 1,
          icon: require('@/assets/img/equity-icon/api/ruixinkafei.png'),
          introduce: '瑞幸咖啡-优惠券/代金券介绍',
          info: '瑞幸咖啡-优惠券/代金券'
        },
        {
          title: '哈根达斯',
          key: 1,
          icon: require('@/assets/img/equity-icon/api/hagendas.png'),
          introduce: '哈根达斯-优惠券/代金券介绍',
          info: '哈根达斯-优惠券/代金券'
        },
        {
          title: '达美乐',
          key: 1,
          icon: require('@/assets/img/equity-icon/api/dameile.png'),
          introduce: '达美乐-优惠券/代金券介绍',
          info: '达美乐-优惠券/代金券'
        },
        {
          title: '盒马生鲜',
          key: 3,
          icon: require('@/assets/img/equity-icon/api/hemashengxian.png'),
          introduce: '盒马生鲜-优惠券/代金券介绍',
          info: '盒马生鲜-优惠券/代金券'
        },
        {
          title: '哔哩哔哩',
          key: 2,
          icon: require('@/assets/img/equity-icon/video/bilibili.png'),
          introduce: '哔哩哔哩-优惠券/代金券介绍',
          info: '哔哩哔哩-优惠券/代金券'
        },
        {
          title: '爱奇艺',
          key: 2,
          icon: require('@/assets/img/equity-icon/video/aqy.png'),
          introduce: '爱奇艺-优惠券/代金券介绍',
          info: '爱奇艺-优惠券/代金券'
        },
        {
          title: '樊登读书',
          key: 5,
          icon: require('@/assets/img/equity-icon/video/fdds.png'),
          introduce: '樊登读书-优惠券/代金券介绍',
          info: '樊登读书-优惠券/代金券'
        },
        {
          title: '酷狗',
          key: 2,
          icon: require('@/assets/img/equity-icon/video/kg.png'),
          introduce: '酷狗-优惠券/代金券介绍',
          info: '酷狗-优惠券/代金券'
        },
        {
          title: '酷我音乐',
          key: 2,
          icon: require('@/assets/img/equity-icon/video/kwyy.png'),
          introduce: '酷我音乐-优惠券/代金券介绍',
          info: '酷我音乐-优惠券/代金券'
        },
        {
          title: '懒人听书',
          key: 5,
          icon: require('@/assets/img/equity-icon/video/lrts.png'),
          introduce: '懒人听书-优惠券/代金券介绍',
          info: '懒人听书-优惠券/代金券'
        },
        {
          title: '咪咕',
          key: 2,
          icon: require('@/assets/img/equity-icon/video/mg.png'),
          introduce: '咪咕-优惠券/代金券介绍',
          info: '咪咕-优惠券/代金券'
        },
        {
          title: '芒果TV',
          key: 2,
          icon: require('@/assets/img/equity-icon/video/mgtv.png'),
          introduce: '芒果TV-优惠券/代金券介绍',
          info: '芒果TV-优惠券/代金券'
        },
        {
          title: 'PPTV',
          key: 2,
          icon: require('@/assets/img/equity-icon/video/pptv.png'),
          introduce: 'PPTV-优惠券/代金券介绍',
          info: 'PPTV-优惠券/代金券'
        },
        {
          title: 'QQ音乐',
          key: 2,
          icon: require('@/assets/img/equity-icon/video/qqyingyue.png'),
          introduce: 'PPTV-优惠券/代金券介绍',
          info: 'PPTV-优惠券/代金券'
        },
        {
          title: '蜻蜓FM',
          key: 5,
          icon: require('@/assets/img/equity-icon/video/qtfm.png'),
          introduce: 'PPTV-优惠券/代金券介绍',
          info: 'PPTV-优惠券/代金券'
        },
        {
          title: '搜狐影音',
          key: 2,
          icon: require('@/assets/img/equity-icon/video/sf.png'),
          introduce: 'PPTV-优惠券/代金券介绍',
          info: 'PPTV-优惠券/代金券'
        },
        {
          title: '腾讯视频',
          key: 2,
          icon: require('@/assets/img/equity-icon/video/txsp.png'),
          introduce: 'PPTV-优惠券/代金券介绍',
          info: 'PPTV-优惠券/代金券'
        },
        {
          title: '腾讯微云',
          key: 6,
          icon: require('@/assets/img/equity-icon/video/txwy.png'),
          introduce: 'PPTV-优惠券/代金券介绍',
          info: 'PPTV-优惠券/代金券'
        },
        {
          title: '腾讯文学',
          key: 5,
          icon: require('@/assets/img/equity-icon/video/txwx.png'),
          introduce: 'PPTV-优惠券/代金券介绍',
          info: 'PPTV-优惠券/代金券'
        },
        {
          title: '网易蜗牛',
          key: 6,
          icon: require('@/assets/img/equity-icon/video/wywl.png'),
          introduce: 'PPTV-优惠券/代金券介绍',
          info: 'PPTV-优惠券/代金券'
        },
        {
          title: '网易云音乐',
          key: 2,
          icon: require('@/assets/img/equity-icon/video/wyy.png'),
          introduce: 'PPTV-优惠券/代金券介绍',
          info: 'PPTV-优惠券/代金券'
        },
        {
          title: '网易云阅读',
          key: 5,
          icon: require('@/assets/img/equity-icon/video/wyyyd.png'),
          introduce: 'PPTV-优惠券/代金券介绍',
          info: 'PPTV-优惠券/代金券'
        },
        {
          title: '新浪',
          key: 5,
          icon: require('@/assets/img/equity-icon/video/xl.png'),
          introduce: 'PPTV-优惠券/代金券介绍',
          info: 'PPTV-优惠券/代金券'
        },
        {
          title: '喜马拉雅',
          key: 5,
          icon: require('@/assets/img/equity-icon/video/xmly.png'),
          introduce: 'PPTV-优惠券/代金券介绍',
          info: 'PPTV-优惠券/代金券'
        },
        {
          title: '迅雷',
          key: 6,
          icon: require('@/assets/img/equity-icon/video/xunlei.png'),
          introduce: 'PPTV-优惠券/代金券介绍',
          info: 'PPTV-优惠券/代金券'
        },
        {
          title: '优酷',
          key: 2,
          icon: require('@/assets/img/equity-icon/video/youku.png'),
          introduce: 'PPTV-优惠券/代金券介绍',
          info: 'PPTV-优惠券/代金券'
        },
        {
          title: '掌阅',
          key: 5,
          icon: require('@/assets/img/equity-icon/video/zhangyue.png'),
          introduce: 'PPTV-优惠券/代金券介绍',
          info: 'PPTV-优惠券/代金券'
        },
        {
          title: '知乎',
          key: 5,
          icon: require('@/assets/img/equity-icon/video/zhihu.png'),
          introduce: 'PPTV-优惠券/代金券介绍',
          info: 'PPTV-优惠券/代金券'
        },
        {
          title: '中石化',
          key: 4,
          icon: require('@/assets/img/equity-icon/game/zhongshihua.png'),
          introduce: 'PPTV-优惠券/代金券介绍',
          info: 'PPTV-优惠券/代金券'
        },
        {
          title: '中石油',
          key: 4,
          icon: require('@/assets/img/equity-icon/game/zsy.png'),
          introduce: '中石油-优惠券/代金券介绍',
          info: '中石油-优惠券/代金券'
        }
      ],
      goodsList: [
        {
          title: '机酒出行',
          key: 0
        },
        {
          title: '美食饮品',
          key: 1
        },
        {
          title: '影音娱乐',
          key: 2
        },
        {
          title: '生活服务',
          key: 3
        },
        {
          title: '油卡充值',
          key: 4
        },
        {
          title: '知识阅读',
          key: 5
        },
        {
          title: '其它',
          key: 6
        }
      ],
      foreCardList: [
        {
          bgImg: require('@/assets/img/equity/equ-api-one.jpg'),
          icon: require('@/assets/img/equity/equ-api.png'),
          title: 'API',
          desc: '提供话费、流量、视频音频、生活卡券及油品等标准技术接口、数据互通'
        },
        {
          bgImg: require('@/assets/img/equity/equ-api-two.jpg'),
          icon: require('@/assets/img/equity/equ-sc.png'),
          title: '商城入驻',
          desc: '为商户提供店铺管理、订单管理、产品上架、平台运营、售后维护和数据分析等功能服务。'
        },
        {
          bgImg: require('@/assets/img/equity/equ-api-three.jpg'),
          icon: require('@/assets/img/equity/equ-h5.png'),
          title: 'H5',
          desc: '用户可自由调整页面布局、内容模块， 实现H5界面定制化、快速上线。'
        },
        {
          bgImg: require('@/assets/img/equity/equ-api-fore.jpg'),
          icon: require('@/assets/img/equity/equ-caigou.png'),
          title: '批量采购',
          desc: '卡券、礼品卡等批量采购、可靠高效'
        }
      ],
      columnList: [
        {
          title: '权益场景',
          desc: '',
          icon: require('@/assets/img/equity/equ-footer-one.png')
        },
        {
          title: '技术和服务保障',
          desc: '',
          icon: require('@/assets/img/equity/equ-footer-two.png')
        },
        {
          title: '权益产品',
          desc: '',
          icon: require('@/assets/img/equity/equ-footer-three.png')
        }
      ],
      activeLiIndex: 0,
      goodsIndex: 0,
      goodsInfo: {}
    };
  },
  computed: {
    goodsDataList() {
      let tem = this.dataList.filter(o => o.key === this.activeLiIndex);
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.goodsInfo = tem[0];
      return tem;
    }
  },
  methods: {
    /** 事件#切换类型 */
    onClickType(index) {
      this.goodsIndex = 0;
      this.activeLiIndex = index;
    },
    /** 事件#切换产品 */
    onClickGoods(item, index) {
      this.goodsInfo = item;
      this.goodsIndex = index;
    }
  }
};
</script>

<style lang="less" scoped>
.equity {
  margin-bottom: 100px;
  .header-banner {
    height: 483px;
    width: 100%;
    background-image: url('../../assets/img/equity/eq-logo.jpg');
    // background-size: 100% 100%;
    background-position: center;
    background-size: center center;
    .title-box {
      padding-top: 99px;
      .banner-one-title,
      .banner-two-title {
        margin-left: 45px;
      }
      .banner-two-title {
        margin: 10px 45px;
      }
      .btn-line {
        margin-top: 120px;
        margin-left: 49px;
        border-radius: 6px;
        position: relative;
      }
      .btn-line:hover::before {
        content: '微信扫一扫';
        display: block;
        width: 160px;
        top: 54px;
        left: -1px;
        position: absolute;
        background-size: 100% 100%;
        padding: 2px;
        border: 1px solid #f4f4f4;
        opacity: 1;
        transition-duration: 300ms;
        background-color: #fff;
        z-index: 8;
        text-align: center;
        color: @lupay-text-color-theme;
      }
      .btn-line:hover::after {
        content: '';
        display: block;
        width: 160px;
        height: 160px;
        top: 80px;
        left: -1px;
        position: absolute;
        background-image: url('../../assets/img/lupaywechat.png');
        background-size: 100% 100%;
        padding: 2px;
        border: 1px solid #f4f4f4;
        opacity: 1;
        transition-duration: 300ms;
      }
    }
  }
  &-introduce {
    margin-top: 77px;
    .two-leave-title {
      color: rgba(0, 0, 0, 0.8);
      font-size: 22px;
      margin-left: 49px;
    }
    .tab-bar-menu {
      margin-left: 49px;
      margin-top: 45px;
      ul {
        position: relative;
      }
      li {
        color: rgba(0, 0, 0, 0.8);
        font-size: 16px;
        margin: 0 24px;
        cursor: pointer;
        position: relative;
      }
      li:nth-child(1) {
        margin-left: 3px;
      }
      li:hover {
        color: @lupay-text-color-theme;
        font-size: 16px;
      }
      li:before {
        content: '';
        width: 25px;
        height: 2px;
        display: block;
        position: absolute;
        bottom: -8px;
        left: calc(50% - 12.5px);
        background-color: @lupay-bg-color-white;
        transform: scaleX(0);
        transition-duration: 300ms;
      }
      li:hover:before {
        content: '';
        width: 25px;
        height: 2px;
        display: block;
        position: absolute;
        bottom: -8px;
        left: calc(50% - 12.5px);
        background-color: @lupay-text-color-theme;
        transform: scaleX(1);
        transition-duration: 300ms;
      }
      .li-checked {
        color: @lupay-text-color-theme;
        transition-duration: 300ms;
      }
      .li-checked:before {
        content: '';
        width: 25px;
        height: 2px;
        display: block;
        position: absolute;
        bottom: -8px;
        left: calc(50% - 12.5px);
        background-color: @lupay-text-color-theme;
        transform: scaleX(1);
        transition-duration: 300ms;
      }
    }
    .goods {
      margin-top: 45px;
      margin-bottom: 30px;
      padding-bottom: 15px;
      overflow: auto;
      scrollbar-width: none; /* firefox */
      -ms-overflow-style: none; /* IE 10+ */
      &::-webkit-scrollbar {
        // chrome safari
        width: 0px;
        height: 10px;
        display: none;
        transition-duration: 1500ms;
      }
      &-card {
        margin: 0 60px;
        // cursor: pointer;
        min-width: 80px;
        img {
          width: 80px;
          height: 80px;
        }
        .desc {
          color: rgba(0, 0, 0, 0.8);
          font-size: 16px;
          margin-top: 8px;
        }
        .desc-delected {
          color: rgba(34, 78, 219, 1);
          font-size: 16px;
          font-weight: 400;
        }
      }
    }

    .goods-center {
      height: 170px;
    }

    .goods:hover {
      overflow: auto;
      &::-webkit-scrollbar {
        width: 0px;
        height: 10px;
        display: block;
        transition-duration: 1500ms;
      }
      /*滚动条滑块*/
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba(144, 147, 153, 1.5);
      }
      /*滚动槽*/
      &::-webkit-scrollbar-track-piece {
        width: 2px;
      }
    }

    .goods-info {
      width: 1200px;
      height: 320px;
      padding: 45px 20px 0 49px;
      background: rgba(224, 240, 255, 0.2);
      .goods-box {
        min-height: 86px;
      }
      .goods-box:last-child {
        margin-top: 45px;
      }
      .three-leave-ttitle {
        border-left: 2px solid @lupay-text-color-theme;
        color: @lupay-text-color-theme;
        font-size: 16px;
        padding-left: 10px;
        margin-bottom: 20px;
      }
      .desc {
        color: rgba(0, 0, 0, 0.8);
        font-size: 14px;
        line-height: 30px;
      }
    }
  }
  &-ecology,
  &-solutions {
    margin-top: 77px;
    .two-leave-title {
      color: rgba(0, 0, 0, 0.8);
      font-size: 22px;
      margin-left: 49px;
    }
    .desc {
      width: 720px;
      color: rgba(0, 0, 0, 0.8);
      font-size: 14px;
      margin-top: 45px;
      margin-left: 49px;
    }
  }
  .fore-card {
    margin-top: 88px;
  }
}
</style>
